/**
 * 魔法搜索框
 * @description 符号系统
 *  @:
 *  #: 标签分类
 *  ^:
 *  $:
 *  &:
 *  ?:
 *  ~:
 * @todo 待实现的功能
 *  - 智能提示 + 自动补全
 *  - 动效
 *  - debounce + throttle
 *  - ?接入 Rxjs
 *  - 搜索按钮滑动功能
 *  - 监听设备摇动
 *  - 监听输入框鼠标滚动
 *  - placeholder 显示一些使用 tips（计算停留时间）
 *  - 背景音乐
 *  - 上上下下左左右右 BA => Open New World
 */

import React, { SFC } from 'react';
import styled from '@emotion/styled';
// import posed from 'react-pose';
import { Link } from 'gatsby';

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 46px;
  line-height: 46px;
  overflow: hidden;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &::before {
    content: '$';
    line-height: inherit;
    margin-right: -10px;
    padding-left: 15px;
    font-size: 20px;
    z-index: 1;
    color: #c7c7c7;
    background-color: white;
  }

  a:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 320px) {
    width: 100%;
    margin: 0 15px;
  }
`;

const Input = styled.input`
  padding: 0 15px;
  /* min-width: 400px; */
  height: 100%;
  line-height: inherit;
  border: none;
  color: grey;

  &::placeholder {
    color: #c7c7c7;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 320px) {
    min-width: 200px;
    width: 100%;
  }
`;

const Button = styled(Link)`
  padding: 0 20px;
  height: 100%;
  line-height: inherit;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: bold;
  border: none;
  background-image: linear-gradient(124deg, #ff470f, #ff3860, #b86bff, #3273dc);
  background-size: 800% 800%;
  animation: rainbow 8s ease-in-out infinite;

  &:hover {
  }

  @keyframes rainbow {
    0% {
      background-position: 1% 80%;
    }

    50% {
      background-position: 99% 20%;
    }

    100% {
      background-position: 1% 80%;
    }
  }

  @media screen and (max-width: 320px) {
    display: none;
  }
`;

interface IProps {
  title?: string;
  placeholder?: string;
  style?: any;
}

const MagicSearchBar: SFC<IProps> = props => {
  const { placeholder, title, style } = props;
  return (
    <SearchBar style={style}>
      <Input placeholder={placeholder || 'Nothing news :-('} />
      <Button to="/">{title || 'HELLO'}</Button>
    </SearchBar>
  );
};

export default MagicSearchBar;
